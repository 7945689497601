import React, { forwardRef, useMemo, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { SetFraudDetailsDialog } from './SetFraudDetailsDialog';

const EditFraudDetailsDialogAction = forwardRef((props: any, ref: any) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const fraudDetailsInitialValues = useMemo(() => {
    const { fraudDescription, ...user } = props.record;
    return {
      orderId: fraudDescription?.orderId,
      fraudType: user?.fraudReason,
      reason: fraudDescription?.reason,
      dimensions: fraudDescription?.dimensions,
      email: fraudDescription?.email || user?.email,
      fromAddress: fraudDescription?.fromAddress,
      toAddress: fraudDescription?.toAddress,
      ipAddress: fraudDescription?.ipAddress,
      paymentIntent: fraudDescription?.paymentIntent,
      phone: fraudDescription?.phone || user?.phone,
      weight: fraudDescription?.weight,
    };
  }, [props.record]);

  return (
    <>
      <MenuItem ref={ref} onClick={handleOpenDialog}>
        Edit fraud details
      </MenuItem>
      {openDialog && (
        <SetFraudDetailsDialog
          open={openDialog}
          onCancel={handleCloseDialog}
          user={props.record}
          initialValues={fraudDetailsInitialValues}
        />
      )}
    </>
  );
});

export default EditFraudDetailsDialogAction;
