import { get, isEmpty, toInteger } from 'lodash';

export const required = (value) => {
  if (Array.isArray(value)) {
    return value.length ? undefined : 'Required';
  }

  return value || typeof value === 'number' ? undefined : 'Required';
};

export const requiredIf = (testFn) => (value, allValues) =>
  testFn(value, allValues) ? required(value) : undefined;

export const requireDefined = (value) => (value === undefined ? 'Required' : undefined);

export const requiredIfEmpty = (fieldName) => (value, allValues) =>
  value || get(allValues, fieldName, false) ? undefined : 'Required';

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const maxLength15 = maxLength(15);

export const minLength = (min) => (value) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const minLength2 = minLength(2);

export const number = (value) => (value && isNaN(Number(value)) ? 'Must be a number' : undefined);

export const minValue = (min) => (value) =>
  value && value < min ? `Must be at least ${min}` : undefined;

export const minValue13 = minValue(13);

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const tooYoung = (value) =>
  value && value < 13 ? 'You do not meet the minimum age requirement!' : undefined;

export const aol = (value) =>
  value && /.+@aol\.com/.test(value) ? 'Really? You still use AOL for your email?' : undefined;

export const alphaNumeric = (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value) ? 'Only alphanumeric characters' : undefined;

export const phoneNumber = (value) =>
  value && !/^\+[1-9]\d{1,14}$/i.test(value)
    ? 'Invalid phone number, must be number up to fifteen digits in length starting with a ‘+’'
    : undefined;

export const usPhoneNumber = (value) =>
  value && !/^\d{10}$/i.test(value)
    ? 'Invalid phone number, must be number of 10 digits in length'
    : undefined;

export const password = (value) =>
  value && value.length >= 6 && value.match(/[a-z]/) && value.match(/[A-Z]/) && value.match(/\d/)
    ? undefined
    : 'Password of at least 6 characters must contain at least one uppercase letter, one lowercase letter and one number';

export const confirmPassword = (value, allValues) =>
  value && allValues.password && value !== allValues.password
    ? 'Password does not match the confirm password'
    : undefined;

export const mustBeChecked = (value) => (value && Boolean(value) ? undefined : 'Must be checked');

export const regex = (pattern, message) => (value) =>
  !isEmpty(value) && !pattern.test(value) ? message : undefined;

export const maxWeight = (maxWeightLb, maxWeightOz, lbName, ozName) => (value, values) =>
  value &&
  12 * toInteger(get(values, lbName)) + toInteger(get(values, ozName)) >
    12 * maxWeightLb + maxWeightOz
    ? `Weight should be less or equal to ${maxWeightLb}lb ${maxWeightOz}oz`
    : undefined;

export const composeValidators =
  (...validators) =>
  (value, values) =>
    validators.reduce((error, validator) => error || validator(value, values), undefined);

export const onlyUSPhones = (value) =>
  value ? `+1${value.replace('+1', '').replace(/[^\d]/g, '')}` : value;

export const positive = (value) => (value && Number(value) > 0 ? undefined : 'Must be positive');

export const maxValue = (max) => (value) =>
  value && value > max ? `Must be less or equal to ${max}` : undefined;
