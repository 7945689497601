import React, { useEffect, useState } from 'react';
import { useDataProvider, useRefresh, useVersion } from 'react-admin';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import { Dialog } from 'components/common';

import formatPrice from 'services/helpers/formatPrice';
import { pluralize } from 'services/helpers/formatters';
import { Input } from 'components/form';
import { composeValidators, maxValue, required } from 'components/form/validationRules';
import { NumberFormat, numberInputFormatter } from 'services/helpers/inputFormatters';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    '& > *': {
      padding: theme.spacing(2, 0),
    },
  },
  currencyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: 16,
    color: theme.palette.secondary.contrastText,
    fontWeight: 700,
  },
  button: {
    textTransform: 'none',
    color: theme.palette.secondary.contrastText,
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
}));

interface Props {
  userId: string;
}

enum Currency {
  USD = 'USD',
  Label = 'LABEL',
}

interface LimitsData {
  balance: number;
  currency: Currency;
  autoRefillEnabled: boolean;
  budgetLimit: number;
  budgetDays: number;
  spent: number;
}

interface FormValues {
  budgetDays: string;
  budgetLimit: string;
  currency?: Currency;
}

const SpendingLimitsSection = ({ userId }: Props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const version = useVersion();

  const [limits, setLimits] = useState<LimitsData[]>([]);
  const [formInitValues, setFormInitValues] = useState<FormValues | undefined>();
  const [resetting, setResetting] = useState<boolean>(false);

  useEffect(() => {
    const getSpendingLimits = async () => {
      const { data } = await dataProvider.get('spendingLimits', { userId });

      setLimits(data);
    };

    getSpendingLimits();
  }, [dataProvider, userId, version]);

  const handleClose = () => {
    setFormInitValues(undefined);
  };

  const handleSubmitForm = async (values: FormValues) => {
    try {
      const response = await dataProvider.post('spendingLimits', { userId, data: { ...values } });

      handleClose();

      refresh();

      return response;
    } catch (error: any) {
      return { [FORM_ERROR]: error.message };
    }
  };

  return (
    <Card className={classes.container}>
      {limits.map((item, index) => {
        const lastItem = index === limits.length - 1;

        const formattedBudgetLimit =
          item.currency === Currency.Label
            ? pluralize(item.budgetLimit, 'label')
            : formatPrice(item.budgetLimit);

        const formattedSpent =
          item.currency === Currency.Label
            ? pluralize(item.spent, 'label')
            : formatPrice(item.spent);

        const handleEdit = () => {
          const values: FormValues = {
            budgetDays: String(item.budgetDays),
            budgetLimit: String(item.budgetLimit),
            currency: item.currency,
          };

          setFormInitValues(values);
        };

        const handleReset = async () => {
          try {
            setResetting(true);
            await dataProvider.delete('spendingLimits', { userId });
            refresh();
          } catch (error: any) {
          } finally {
            setResetting(false);
          }
        };

        return (
          <React.Fragment key={item.currency}>
            <Box className={classes.currencyContainer}>
              <Typography className={classes.label}>{item.currency}</Typography>

              <Typography>
                {formattedBudgetLimit} / {pluralize(item.budgetDays, 'day')}
              </Typography>

              <Typography>{formattedSpent}</Typography>

              <Box className={classes.buttonsContainer}>
                <Button className={classes.button} variant="outlined" onClick={handleEdit}>
                  Edit
                </Button>

                <Button
                  className={classes.button}
                  disabled={resetting}
                  variant="outlined"
                  onClick={handleReset}>
                  Reset
                </Button>
              </Box>
            </Box>
            {!lastItem && (
              <Box>
                <Divider variant="middle" />
              </Box>
            )}
          </React.Fragment>
        );
      })}

      {!!formInitValues && (
        <Form<FormValues>
          onSubmit={handleSubmitForm}
          initialValues={formInitValues}
          render={({ handleSubmit, submitting, submitError }) => {
            return (
              <Dialog
                open={!!formInitValues}
                onCancel={handleClose}
                title="Edit budget limits"
                onConfirm={handleSubmit}
                submitting={submitting}
                confirmText="Save"
                error={submitError}>
                <Input
                  name="budgetDays"
                  label="Budget days"
                  validate={composeValidators(required, maxValue(30))}
                  formatValue={numberInputFormatter[NumberFormat.Integer]}
                />

                <Input
                  name="budgetLimit"
                  label="Budget limit"
                  formatValue={numberInputFormatter[NumberFormat.Currency]}
                  validate={required}
                />
              </Dialog>
            );
          }}
        />
      )}
    </Card>
  );
};

export default SpendingLimitsSection;
