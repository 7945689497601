import React from 'react';
import { get } from 'lodash';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

import { ReactComponent as VerifiedIcon } from 'assets/icons/verified.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const PhoneField = ({ record = {}, ...rest }) => {
  const classes = useStyles();

  const phone = get(record, 'phoneNumber');
  const phoneVerified = get(record, 'phoneVerified');

  return (
    <div className={classes.container}>
      <Typography variant="body1">{phone}</Typography>
      {phoneVerified && <VerifiedIcon />}
    </div>
  );
};

export default PhoneField;
