export const capitalize = (str: string): string =>
  `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;

export const pluralize = (count: number, singular: string, plural?: string) => {
  if (count === 1) {
    return `${count} ${singular}`;
  } else {
    return `${count} ${plural ?? singular + 's'}`;
  }
};
