import React, { forwardRef } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { useDataProvider, useRefresh, useNotify } from 'react-admin';

const BypassFraudAction = forwardRef((props: any, ref: any) => {
  const {
    record: { id: rolloId, cognitoIds, cognitoUserId, fraudAllowedAt: bypassFraud },
  } = props;

  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleBypassFraud = async () => {
    try {
      await dataProvider.post('bypassFraud', {
        data: {
          bypass: !bypassFraud,
          rolloId,
          cognitoId: cognitoIds?.[0] ?? cognitoUserId,
        },
      });

      refresh();
    } catch (error: any) {
      notify(
        typeof error === 'string' ? error : error.message || 'ra.notification.http_error',
        'warning'
      );
    }
  };

  return (
    <MenuItem ref={ref} onClick={handleBypassFraud}>
      {!bypassFraud ? 'Add to fraud bypass list' : 'Remove from fraud bypass list'}
    </MenuItem>
  );
});

export default BypassFraudAction;
