import React, { forwardRef, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { useDataProvider, useRefresh, useNotify } from 'react-admin';
import { Form } from 'react-final-form';

import { Dialog } from 'components/common';
import { Input } from 'components/form';
import { required, minLength, maxLength, composeValidators } from 'components/form/validationRules';

const EnableUserAction = forwardRef((props: any, ref: any) => {
  const {
    record: { fraudBlockedAt, blockedAt, id: rolloId, cognitoIds, cognitoUserId: cognitoId },
  } = props;

  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleEnableUser = async () => {
    try {
      await dataProvider.post('enableUser', {
        data: {
          rolloId,
          cognitoId: cognitoIds?.[0] ?? cognitoId,
        },
      });

      refresh();
    } catch (error: any) {
      notify(
        typeof error === 'string' ? error : error.message || 'ra.notification.http_error',
        'warning'
      );
    }
  };

  const handleDisableUser = async ({ note, reason }) => {
    try {
      const response = await dataProvider.post('disableUser', {
        data: {
          rolloId,
          cognitoId: cognitoIds?.[0] ?? cognitoId,
          reason,
          // note,
        },
      });

      handleCloseDialog();

      refresh();

      return response;
    } catch (error: any) {
      notify(
        typeof error === 'string' ? error : error.message || 'ra.notification.http_error',
        'warning'
      );
    }
  };

  return (
    <>
      <MenuItem
        ref={ref}
        onClick={!fraudBlockedAt && !blockedAt ? handleOpenDialog : handleEnableUser}>
        {!fraudBlockedAt && !blockedAt ? 'Block user' : 'Unblock user'}
      </MenuItem>
      <Form
        onSubmit={handleDisableUser}
        initialValues={{ note: '', reason: '' }}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Dialog
                open={openDialog}
                onCancel={handleCloseDialog}
                title="Block reason"
                onConfirm={handleSubmit}
                confirmText="Block"
                submitting={submitting}>
                <Input
                  name="reason"
                  label="Reason"
                  validate={composeValidators(required, minLength(5), maxLength(200))}
                />
                {/* <Input
                  name="note"
                  label="Note"
                  validate={composeValidators(minLength(5), maxLength(200))}
                /> */}
              </Dialog>
            </form>
          );
        }}
      />
    </>
  );
});

export default EnableUserAction;
