import React, { useState } from 'react';
import { useDataProvider, useRefresh, useNotify } from 'react-admin';
import { Form } from 'react-final-form';

import { Button, makeStyles } from '@material-ui/core';
import { UserDetails } from '../types';
import { Dialog } from 'components/common';
import { Input } from 'components/form';
import { required, minLength, maxLength, composeValidators } from 'components/form/validationRules';

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none',
    color: theme.palette.secondary.contrastText,
  },
}));

interface Props {
  user: UserDetails;
}

const EnableUserButton = ({ user }: Props) => {
  const { blockedAt, id: rolloId, cognitoUserId: cognitoId } = user;

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const [loading, setLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleEnableUser = async () => {
    try {
      setLoading(true);

      await dataProvider.post(blockedAt ? 'enableUser' : 'disableUser', {
        data: {
          rolloId,
          cognitoId,
        },
      });

      refresh();
    } catch (error: any) {
      notify(
        typeof error === 'string' ? error : error.message || 'ra.notification.http_error',
        'warning'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDisableUser = async ({ note, reason }) => {
    try {
      const response = await dataProvider.post('disableUser', {
        data: {
          rolloId,
          cognitoId,
          reason,
          // note,
        },
      });

      handleCloseDialog();
      refresh();

      return response;
    } catch (error: any) {
      notify(
        typeof error === 'string' ? error : error.message || 'ra.notification.http_error',
        'warning'
      );
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={!blockedAt ? handleOpenDialog : handleEnableUser}
        disabled={loading}
        className={classes.button}>
        {!blockedAt ? 'Block user' : 'Unblock user'}
      </Button>
      {openDialog && (
        <Form
          onSubmit={handleDisableUser}
          initialValues={{ note: '', reason: '' }}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Dialog
                  open={openDialog}
                  onCancel={handleCloseDialog}
                  title="Block reason"
                  onConfirm={handleSubmit}
                  confirmText="Block"
                  submitting={submitting}>
                  <Input
                    name="reason"
                    label="Reason"
                    validate={composeValidators(required, minLength(5), maxLength(200))}
                  />
                  {/* <Input
                    name="note"
                    label="Note"
                    validate={composeValidators(minLength(5), maxLength(200))}
                  /> */}
                </Dialog>
              </form>
            );
          }}
        />
      )}
    </>
  );
};

export default EnableUserButton;
