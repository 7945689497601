import React, { useRef, useEffect } from 'react';
import { Form } from 'react-final-form';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { FORM_ERROR, FormApi } from 'final-form';

import { Dialog } from 'components/common';
import { Input, Select } from 'components/form';
import { required, minLength, maxLength, composeValidators } from 'components/form/validationRules';

interface FormValues {
  fraudType: string;
  reason: string;
  dimensions?: string;
  email?: string;
  fromAddress?: string;
  toAddress?: string;
  ipAddress?: string;
  orderId?: string;
  paymentIntent?: string;
  phone?: string;
  weight?: string;
}

interface Props {
  open: boolean;
  onCancel: () => void;
  user: Rollo.UserDetailsDTO;
  initialValues?: FormValues;
}

const defaultValues: FormValues = {
  fraudType: '',
  reason: '',
};

const fraudTypesOptions = [
  { label: 'MANUAL', value: 'MANUAL' },
  { label: 'PACKAGE_DIMENSION', value: 'PACKAGE_DIMENSION' },
  { label: 'PAYMENT_INTENT', value: 'PAYMENT_INTENT' },
  { label: 'PHONE_NUMBER', value: 'PHONE_NUMBER' },
  { label: 'IP_ADDRESS', value: 'IP_ADDRESS' },
  { label: 'DEBTOR', value: 'DEBTOR' },
];

export const SetFraudDetailsDialog = ({ open, onCancel, user, initialValues }: Props) => {
  const dataProvider = useDataProvider();
  const formRef = useRef<FormApi<FormValues>>();

  const { id: rolloId, cognitoUserId: cognitoId } = user;

  const hasOrderId = initialValues?.orderId;

  const notify = useNotify();
  const refresh = useRefresh();

  useEffect(() => {
    if (formRef.current && initialValues) {
      formRef.current.reset(initialValues);
    }
  }, [user, initialValues]);

  const handleSubmitForm = async (values: FormValues) => {
    try {
      if (initialValues) {
        await dataProvider.update('updateFraudDetails', {
          id: user.id,
          data: values,
        });
      } else {
        await dataProvider.post('suspendAsFraud', {
          data: { ...values, rolloId, cognitoId },
        });
      }

      refresh();
      onCancel();
    } catch (error: any) {
      notify(error?.message);
      return { [FORM_ERROR]: error?.message };
    }
  };

  return (
    <Form<FormValues>
      initialValues={defaultValues}
      onSubmit={handleSubmitForm}
      render={({ handleSubmit, submitting, form }) => {
        formRef.current = form;

        return (
          <Dialog
            open={open}
            onCancel={onCancel}
            title={initialValues ? 'Edit fraud details' : 'Suspend as fraud'}
            onConfirm={handleSubmit}
            submitting={submitting}>
            <Select
              name="fraudType"
              label="Fraud type"
              options={fraudTypesOptions}
              validate={required}
            />
            <Input
              name="reason"
              label="Reason"
              validate={composeValidators(required, minLength(5), maxLength(200))}
              inputProps={{
                multiline: true,
                rows: 3,
              }}
            />
            <Input name="dimensions" label="Dimensions" />
            <Input name="email" label="Email" inputProps={{ InputProps: { readOnly: true } }} />
            <Input name="fromAddress" label="From address" />
            <Input name="toAddress" label="To address" />
            <Input name="ipAddress" label="IP address" />
            <Input
              name="orderId"
              label="Order ID"
              inputProps={{ InputProps: { readOnly: hasOrderId } }}
            />
            <Input name="paymentIntent" label="Payment intent" />
            <Input name="phone" label="Phone" inputProps={{ InputProps: { readOnly: true } }} />
            <Input name="weight" label="Weight" />
          </Dialog>
        );
      }}
    />
  );
};
