import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useDataProvider, useRefresh, useNotify } from 'react-admin';

import Button from '@material-ui/core/Button';

import { SetFraudDetailsDialog } from './SetFraudDetailsDialog';

import { UserDetails } from '../types';

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none',
    color: theme.palette.secondary.contrastText,
  },
}));

interface Props {
  user: UserDetails;
}

const SuspendAsFraudButton = ({ user }: Props) => {
  const { id: rolloId, cognitoUserId: cognitoId } = user;

  const notify = useNotify();
  const refresh = useRefresh();

  const dataProvider = useDataProvider();
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEnableUser = async () => {
    try {
      await dataProvider.post('unblockFraud', {
        data: {
          rolloId,
          cognitoId,
        },
      });

      refresh();
    } catch (error: any) {
      notify(
        typeof error === 'string' ? error : error.message || 'ra.notification.http_error',
        'warning'
      );
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={user.fraudDetectedAt ? handleEnableUser : handleOpen}
        className={classes.button}>
        {user.fraudDetectedAt ? 'Unsuspend as fraud user' : 'Suspend as fraud'}
      </Button>

      {open && <SetFraudDetailsDialog open={open} onCancel={handleClose} user={user} />}
    </>
  );
};

export default SuspendAsFraudButton;
