export enum NumberFormat {
  Integer = 'INTEGER',
  Float = 'FLOAT',
  Currency = 'CURRENCY',
}

export const numberInputFormatter: Record<NumberFormat, (value: string) => string> = {
  [NumberFormat.Float]: (value: string) => value.replace(/[^\d.]/g, '').replace(/\.(?=.*\.)/g, ''),
  [NumberFormat.Integer]: (value: string) => value.replace(/[^\d]/g, ''),
  [NumberFormat.Currency]: (value: string) =>
    value
      .replace(/[^\d.]/g, '')
      .replace(/\.(?=.*\.)/g, '')
      .replace(/^(\d+\.\d{0,2})\d*$/, '$1'),
};
