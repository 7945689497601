import React, { forwardRef, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { SetFraudDetailsDialog } from './SetFraudDetailsDialog';
import { useDataProvider, useRefresh, useNotify } from 'react-admin';

const SuspendAsFraudAction = forwardRef((props: any, ref: any) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const { fraudDetectedAt, id: rolloId, cognitoIds, cognitoUserId: cognitoId } = props.record;

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleEnableUser = async () => {
    try {
      await dataProvider.post('unblockFraud', {
        data: {
          rolloId,
          cognitoId: cognitoIds?.[0] ?? cognitoId,
        },
      });

      refresh();
    } catch (error: any) {
      notify(
        typeof error === 'string' ? error : error.message || 'ra.notification.http_error',
        'warning'
      );
    }
  };

  return (
    <>
      <MenuItem ref={ref} onClick={fraudDetectedAt ? handleEnableUser : handleOpenDialog}>
        {fraudDetectedAt ? 'Unsuspend as fraud user' : 'Suspend as fraud'}
      </MenuItem>
      {openDialog && (
        <SetFraudDetailsDialog open={openDialog} onCancel={handleCloseDialog} user={props.record} />
      )}
    </>
  );
});

export default SuspendAsFraudAction;
