import React, { useEffect, useState, useMemo } from 'react';
import { TextField, DateField, useRedirect, useDataProvider, useVersion } from 'react-admin';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core';
import { keyBy } from 'lodash';

import { Datagrid } from 'components/list';
import Pagination from 'components/pagination/Pagination';
import { ActionField, PriceField } from 'components/fields';
import PhoneField from './PhoneField';

import EnableUserAction from './EnableUserAction';
import BypassFraudAction from './BypassFraudAction';
import SuspendAsFraudAction from './SuspendAsFraudAction';
import ReviewAction from './ReviewAction';

const useStyles = makeStyles((theme) => ({
  expandHeader: {
    width: 'auto',
  },
  expand: {
    backgroundColor: theme.palette.background.default,
  },
  expandCell: {
    padding: '16px 0',
  },
}));

const ExpandedArea = (props) => {
  const { record } = props;

  const [currentTotal, setCurrentTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(5);

  const redirect = useRedirect();

  const data = useMemo(() => {
    const start = (page - 1) * perPage;
    const end = (page - 1) * perPage + perPage;

    const paginatedData = record.users.slice(start, end);
    return keyBy(paginatedData, ({ id }) => id);
  }, [page, perPage, record.users]);

  const ids = Object.entries(data).map(([id]) => id);

  useEffect(() => {
    setCurrentTotal(record.users.length);
  }, [record.users]);

  const updatePerPage = (newPerPage) => {
    if (page > Math.ceil(currentTotal / newPerPage)) {
      setPage(1);
    }
    setPerPage(newPerPage);
  };

  return (
    <>
      <Datagrid
        data={data}
        ids={ids}
        resource="sharedFingerprints"
        currentSort={{ field: 'id' }}
        rowClick={(id) => {
          redirect('show', '/users', id);
        }}>
        <TextField source="userName" label="Name" />
        <PhoneField label="Phone" />
        <TextField source="email" label="Email" />
        <DateField
          source="createdAt"
          label="Registered"
          options={{
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          }}
        />
        <TextField source="shipments" label="Shipments" />
        <PriceField source="totalSpentAmount" label="Spend" />
        <ActionField>
          <EnableUserAction />
          <BypassFraudAction />
          <SuspendAsFraudAction />
        </ActionField>
      </Datagrid>
      <Pagination
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={updatePerPage}
        total={currentTotal}
      />
    </>
  );
};

const SharedFingerprintsList = (props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const version = useVersion();

  const [fingerprints, setFingerprints] = useState<any>([]);
  const [currentTotal, setCurrentTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const getFingerprintsList = async () => {
      const { data, total } = await dataProvider.getList('sharedFingerprints', {
        pagination: { page, perPage },
        filter: {
          deleted: true,
        },
      });

      setCurrentTotal(total);
      setFingerprints(data);
      setLoaded(true);
    };

    getFingerprintsList();
  }, [dataProvider, page, perPage, version]);

  const updatePerPage = (newPerPage) => {
    if (page > Math.ceil(currentTotal / newPerPage)) {
      setPage(1);
    }
    setPerPage(newPerPage);
  };

  return (
    <Card>
      <Datagrid
        loaded={loaded}
        expand={<ExpandedArea />}
        isRowExpandable
        rowClick="expand"
        classes={{
          expandHeader: classes.expandHeader,
          expandRoot: classes.expand,
          expandCell: classes.expandCell,
        }}
        showExpandButton={false}
        data={keyBy(fingerprints, 'id')}
        ids={fingerprints.map(({ id }) => id)}
        resource="sharedFingerprints"
        currentSort={{ field: 'id', order: 'DESC' }}>
        <TextField source="fingerprint" label="Fingerprint" />
        <ReviewAction
          source="users.length"
          label="Users"
          textAlign="right"
          apiResource="fingerprint"
        />
      </Datagrid>
      <Pagination
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={updatePerPage}
        total={currentTotal}
      />
    </Card>
  );
};

export default SharedFingerprintsList;
