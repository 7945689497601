import React from 'react';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';

import { get } from 'lodash';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core';
import { Checkmark } from 'icons';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
    justifyContent: 'flex-end',
  },
  button: {
    padding: 0,
  },
}));

const reviewResourses = {
  ipAddress: 'reviewSharedIpAddress',
  fingerprint: 'reviewSharedFingerprint',
  warehouseAddress: 'reviewSharedWarehouse',
};

const ReviewIpAddressAction = (props) => {
  const { record, source, apiResource } = props;

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleClick = async (e) => {
    try {
      e.stopPropagation();

      await dataProvider.post(reviewResourses[apiResource], {
        ...(apiResource === 'warehouseAddress'
          ? {
              data: { ...record[apiResource] },
            }
          : {
              data: {
                [apiResource]: record[apiResource],
              },
            }),
      });

      refresh();
    } catch (error: any) {
      notify(error.message);
    }
  };

  return (
    <Box className={classes.container}>
      <Typography component="span">{get(record, source)}</Typography>

      <IconButton onClick={handleClick} className={classes.button}>
        <Checkmark />
      </IconButton>
    </Box>
  );
};

export default ReviewIpAddressAction;
